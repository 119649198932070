<template>
<div>
    <div class="funds-tab">
        <v-tabs v-model="tab" fixed-tabs centered dark icons-and-text height="auto" :hide-slider="true">
            <!-- <v-tabs-slider></v-tabs-slider> -->
            <v-tab href="#deposit" dark>{{$t(`label.deposit`)}}</v-tab>
            <v-tab href="#withdrawal" dark>{{$t(`label.withdrawal`)}}</v-tab>
        </v-tabs>
    </div>
    <v-tabs-items v-model="tab" :click="changeTab()" touchless>
        <v-tab-item :value="'deposit'">
            <deposit></deposit>
        </v-tab-item>
        <v-tab-item :value="'withdrawal'">
            <withdrawal ref="withdrawal"></withdrawal>
        </v-tab-item>
    </v-tabs-items>
</div>
</template>

<script>
import Deposit from '@/views/bank/deposit.vue'
import Withdrawal from '@/views/bank/withdrawal.vue'

export default {
    name: 'funds',
    components: {
        Deposit,
        Withdrawal
    },
    data() {
        return {
            tab: this.$route.params.tab
        }
    },
    created() {
        this.tab = this.$route.params.tab;
        this.$eventHub.$on('change-funds-tab', (tab) => {
            this.tab = tab
        })
    },
    methods: {
        changeTab() {
            if(this.tab == 'withdrawal') {
                console.log('withdrawal')
                // this.$refs.withdrawal.openConfirmationDialog()
                this.$eventHub.$emit('open-confirm-dialog')
            }
        },
    }
}
</script>

<style lang="scss">
.funds-tab {
    padding: 12px;
    background: var(--v-primary-base);

    .v-tabs-bar {
        background: var(--v-primary-base) !important;
    }

    .v-tab {
        background: #514bbe;
        color: #ffffff !important;
        text-transform: capitalize;
        padding: 4px;
    }

    .v-tab--active {
        background: #f56908;

        &::before {
            content: '';
            padding: 8px;
            background: #f56908;
        }
    }
}

.funds-container {
    max-width: 1240px;
    margin: auto;

    .funds-wrapper {
        max-width: 744px;
        margin: auto;
        background: var(--v-background-lighten2);

        .funds-input {
            padding-right: 12px;
            font-size: 14px;

            .v-input__slot {
                color: transparent;

            }
        }

        .funds-divider {
            width: 100%;
            height: 1px;
            background-color: var(--v-divider-base);
            margin: 2px 0px;
        }

        .gateway-button {
            border: 1px solid var(--v-base-base);
            //background: rgba(255, 255, 255, 0.1);
            background-color: var(--v-background-base);
            padding: 8px;
            min-width: 100px;
            width: auto;

            .v-btn__content {
                display: flex;
                flex-direction: column;

                img {
                    width: 24px;
                    height: 24px;
                }

                label {
                    text-transform: capitalize;
                }
            }
        }

        .active-gateway-button {
            &.v-btn--active {
                border: 2px solid var(--v-primary-base);
            }
        }

        .section-container {
            position: relative;
            margin-top: 12px;
            padding: 12px;
            background: var(--v-background-lighten1);

            .funds-title-wrapper {
                position: relative;
                padding-left: 12px;
                margin-bottom: 8px;

                .funds-title::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background-color: var(--v-primary-base);
                }
            }

            .button-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 12px;
                margin-top: 8px;
            }
        }

        .funds-button {
            background: var(--v-primary-base);
            border-radius: 4px;
            width: 100%;
        }

        .v-stepper__content {
            padding: unset;
        }
    }
}
</style>
