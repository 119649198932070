<template>
  <div class="funds-container">
    <v-card elevation="0" class="funds-wrapper">
      <v-row no-gutters class="withdrawal-wallet">
        <v-col cols="12" class="wallet-label">
          <label class="white--text">{{ $t(`label.mainWallet`) }}</label>
          <div @click="getMemberWallet()" class="refresh-balance-icon"></div>
        </v-col>
        <v-col class="balance">
          <div class="text-capitalize" v-if="notLoading">
            <span v-if="!showBalance">{{ memberWallet.totalBalance | currency(currentCurrency) | maskNumber }}</span>
            <span v-else class="white--text">{{ memberWallet.totalBalance | currency(currentCurrency) }}</span>
          </div>
          <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3"
                               :size="24"></v-progress-circular>
        </v-col>
      </v-row>
      <v-row no-gutters class="section-container" v-if="!this.isPhoneVerified">
        <div class="d-flex align-center">
          <v-img src="/static/image/profile/personal-info-warning.svg" width="25" height="25" :contain="true"></v-img>
          <label class="px-2">{{ $t(`label.phone_not_verify`) }}</label>
          <label class="link-underscore warning-text" @click="this.RedirectToProfilePage">{{
              $t(`button.verify`)
            }}</label>
        </div>

      </v-row>
      <v-form ref="transactionForm">
        <v-col class="section-container no-gutters">
          <div class="funds-title-wrapper">
            <label class="text-capitalize subtitle-1 funds-title">{{ $t(`field.paymentType`) }}</label>
          </div>
          <div class="funds-divider"></div>
          <v-row no-gutters class="button-container">
            <v-col cols="auto" v-for="(gateway, index) in availableMethods" :key="index">
              <v-btn
                :value="gateway.code"
                :input-value="transactionObj.gatewayAccountBankId == gateway.code"
                class="pa-2 gateway-button"
                active-class="active-gateway-button"
                @click="changeWithdrawalMethod(gateway)"
                height="100%"
                outlined
              >
                <img v-if="gateway.code == 'NWLB'" :src="`/static/image/icon/local-bank.png`"
                     alt="jaya9 fast withdrawal" />
                <!--                <img v-else :src="`/static/svg/deposit-ewallet-${gateway.code.toLowerCase()}.svg`" alt="krikya fast withdrawal" />-->
                <label>{{ gateway.name.toUpperCase() }}</label>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="section-container no-gutters">
          <div class="funds-title-wrapper">
            <label class="text-capitalize subtitle-1 funds-title">{{ $t(`label.amount`) }}</label>
          </div>
          <div class="funds-divider"></div>
          <v-text-field
            :prefix="currencySymbolMapp(currentCurrency)"
            :placeholder="
              stringFormat(
                $t(`fieldHint.minMax`),
                selectedMethod != '' ? currencyFormatter(selectedMethod.minAmount, transactionObj.currency) : currencyFormatter(900, transactionObj.currency),
                selectedMethod != '' ? currencyFormatter(selectedMethod.maxAmount, transactionObj.currency) : currencyFormatter(90000, transactionObj.currency)
              )
            "
            :enabledLabel="false"
            class="funds-input"
            clearable
            type="number"
            v-model.trim="transactionObj.amount"
            :rules="validator.withdrawalAmountRules(2, selectedMethod != '' ? selectedMethod.minAmount : 100, selectedMethod != '' ? selectedMethod.maxAmount : 10000, transactionObj.currency)"
          ></v-text-field>
        </v-col>
        <v-row no-gutters class="section-container" v-if="transactionObj.methodCode != 'NWLB'">
          <div class="funds-title-wrapper">
            <label class="text-capitalize subtitle-1 funds-title">{{ $t(`field.phoneNumber`) }}</label>
          </div>
          <div class="funds-divider"></div>
          <v-col lg="4" md="5" v-if="memberContacts.length<=1">
            <app-form-field :delete-text="changeNumber" :prefix="mobilePrefix" :isOptional="true" :enabledLabel=false
                            v-model.trim="transactionObj.memberBankAccountNumber" :label="$t(`field.phoneNumber`)"
                            :readonly="true"
                            :rules="validator.mobileNumberRules('my')">
            </app-form-field>
          </v-col>
          <v-col lg="4" md="5" v-else>
            <!-- <app-form-field :delete-text="changeNumber" :prefix="mobilePrefix" :isOptional="true" :enabledLabel=false
                            v-model.trim="transactionObj.memberBankAccountNumber" :label="$t(`field.phoneNumber`)"
                            :readonly="true"
                            :rules="validator.mobileNumberRules('my')">
            </app-form-field> -->
            <!-- <app-form-field type="select" :items="memberContacts" :label="$t(`field.phoneNumber`)" v-model.trim="transactionObj.memberBankAccountNumber" :rules="[v=>v!='' || 'phone number required']" :isOptional="false" required>
            </app-form-field> -->
            <v-select
              v-model.trim="transactionObj.memberBankAccountNumber"
              item-text="text"
              item-value="value"
              :items="memberContacts"
              :rules="[v => !!v ||  $t(`fieldErrorMessage.requiredDropDownField`)]"
              label="Select your phone"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters class="section-container" v-if="transactionObj.methodCode == 'NWLB'">
          <div class="funds-title-wrapper">
            <label class="text-capitalize subtitle-1 funds-title">{{ $t(`label.yourBankAccountDetails`) }}</label>
          </div>
          <div class="funds-divider"></div>
          <v-col cols="12" md="5" class="pa-0">
            <app-form-field
              v-model.trim="transactionObj.memberBankAccountId"
              :label="$t(`field.memberBank`)"
              type="select"
              :rules="validator.dropDownListRules()"
              :items="memberBankAccounts"
              :change="this.updateSelectedMemberBankAccount"
              :displayText="this.memberBankAccountDisplayText"
            ></app-form-field>
            <div v-if="transactionObj.memberBankAccountId">
              <app-form-field v-model.trim="transactionObj.memberBankName" :readonly="true"></app-form-field>
              <app-form-field v-model.trim="transactionObj.memberBankAccountNumber" :readonly="true"></app-form-field>
              <app-form-field v-model.trim="transactionObj.memberBankAccountName" :readonly="true"></app-form-field>
            </div>
          </v-col>
          <div class="pb-2 text-center" v-if="false">
            <v-btn text :ripple="false"
                   class="text-uppercase font-weight-bold text-center navigator_active--text text-decoration-underline"
                   @click="openNewBankAccountDialog">
              {{ $t(transactionObj.memberBankAccountId != "" ? `label.changeBankAccount` : `label.addBankAccount`) }}
            </v-btn>
          </div>
        </v-row>
        <v-row no-gutters class="pa-3">
          <app-button :disabled="!this.isPhoneVerified" class="funds-button white--text" :title="$t(`label.withdrawal`)"
                      :action="this.submitTransaction"></app-button>
        </v-row>
      </v-form>
    </v-card>

    <app-dialog :dialogShow="pageDialogShow" :max-width="450" :title="pageDialog.title"
                :closeAction="this.pageDialog.dialogXButton">
      <app-page-dialog :dialogMessageTitle="pageDialog.messageTitle" :dialogMessage="pageDialog.message"
                       :dialogButton="pageDialog.button"></app-page-dialog>
    </app-dialog>
    <app-dialog :dialogShow="newBankAccountDialogShow" :max-width="600" :title="$t(`label.bankAccount`)"
                :closeAction="this.closeNewBankAccountDialog">
      <app-account
        :bankAccountDetail="bankAccountDetail"
        :showFullBankForm="true"
        :banks="banks"
        :closeNewBankAccountDialog="this.closeNewBankAccountDialog"
        @updateBankAccountObject="updateBankAccountData"
      ></app-account>
    </app-dialog>
    <app-dialog
      :dialogShow="msgDialog.show"
      ref="depositConfirmationDialog"
      :max-width="400"
      :title="$t(`label.withdrawalDetails`)"
      :header_title="$t(`label.withdrawalDetails`)"
      :closeAction="this.onCloseClick"
    >
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="6">
          <label class="payment-dialog-title">{{ $t(`field.withdrawalAmount`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right">
          {{ transactionObj.amount }}
        </v-col>
        <v-col cols="6" class="mt-2 mb-6">
          <label class="payment-dialog-title">{{ $t(`field.paymentType`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right mt-2 mb-6">
          {{ gatewayAccountBankName }}
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="submitPaymentTransaction" depressed color="var(--v-primary-base)"
                 class="primary-button text-capitalize pa-2 font-weight-bold white--text">
            {{ $t(`label.confirm`) }}
          </v-btn>
        </v-col>
      </v-row>
    </app-dialog>
    <app-dialog
      :dialogShow="showWithdrawalUnavailableDialog"
      ref="withdrawalUnavailableDialog"
      :max-width="400"
      :title="$t(`label.system`)"
      :header_title="$t(`label.system`)"
      :closeAction="this.routeToDeposit"
    >
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="12" class="text-center">
          <p>{{ $t(`message.unableWithdrawal`) }}</p>
        </v-col>
        <v-col cols="12" class="text-center">
          <p>
            {{ $t(`message.unableWithdrawal_1`) }}
            <br />
            {{ $t(`message.unableWithdrawal_2`) }}
          </p>
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="routeToDeposit()" color="black" depressed
                 class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">
            {{ $t(`label.confirm`) }}
          </v-btn>
        </v-col>
      </v-row>
    </app-dialog>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="-1" v-model="alertShow"
                width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import _ from "lodash";
import { DevicePlatform, ProcessTransactionResponseType } from "@/constants/enums";
import currencyFormat from "@/filters/currency-format";
import { CACHE_KEY } from "@/constants/constants";
import { ROUTE_NAME } from "@/constants/route.constants";
import format from "string-format";
import AppAccount from "@/components/bank/account.vue";
import { SHARED, SESSION, API } from "@/constants/constants";
import { formValidator, locale, uiHelper, errorCodeHelper } from "@/util";
import {
  MEMBER_WALLET,
  MEMBER_CHECK_TOKEN_VALIDITY,
  MEMBET_CHECK_WITHDRAW_STATUS,
  MEMBER_RESTRICT_WITHDRAWAL, MEMBER_RESET_RESTRICT_WITHDRAWAL
} from "@/store/member.module";
import {
  PAYMENT_SUBMIT_PAYMENT_TRANSACTION,
  PAYMENT_AVAILABLE_METHOD,
  PAYMENT_RESET_TRANSACTION_RESPONSE_STORE,
  PAYMENT_PROCESS_TRANSACTION,
  PAYMENT_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE,
  PAYMENT_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE,
  PAYMENT_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION
} from "@/store/payment.module";
import { WITHDRAWAL_RESTORE } from "@/store/transfer.module";
import { AFFILIATE_PAYMENT_MEMBER_BANK_ACCOUNT, AFFILIATE_PAYMENT_BANK } from "@/store/affiliate.payment.module";
import currencySymbolMapp from "currency-symbol-map";

export default {
  name: "withdrawal",
  metaInfo: {
    title: `${locale.getMessage("meta.withdrawal_title")}`,
    titleTemplate: `%s`,
    meta: [
      { charset: "utf-8" },
      { name: "description", content: `${locale.getMessage("meta.withdrawal_description")}` },
      { name: "keyword", content: `${locale.getMessage("meta.withdrawal_keyword")}` }
    ]
  },
  components: {
    AppAccount
  },
  data: () => ({
    isWithdrawalActive: true,
    showWithdrawalUnavailableDialog: false,
    minAmount: 200,
    maxAmount: 20000,
    isPhoneVerified: false,
    currencySymbolMapp: currencySymbolMapp,
    notLoading: true,
    showBalance: true,
    currentCurrency: uiHelper.getCurrency(),
    currencyFormatter: currencyFormat,
    selectedMethod: "",
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    localBankWithdrawalStep: 1,
    stringFormat: format,
    validator: formValidator,
    newBankAccountDialogShow: false,
    pageDialogShow: false,
    pageDialog: {
      title: "",
      closePageDialog: () => {
      },
      messageTitle: "",
      message: [],
      button: []
    },
    transactionObj: {
      /* TRANSACTION INFO */
      transactionType: SHARED.WITHDRAWAL,
      methodCode: "",
      amount: "",
      depositDateTime: "",
      attachmentFileType: "",
      attachment: "",
      ipAddress: "",
      saveNewBank: false,
      /* MEMBER INFO */
      memberCode: localStorage.getItem(SESSION.USERNAME),
      memberRealName: "",
      currency: localStorage.getItem(SESSION.CURRENCY),
      memberBankAccountId: "",
      memberBankAccountName: "",
      memberBankAccountNumber: "",
      memberBankName: "",
      memberBankProvince: "",
      memberBankBranch: "",
      memberBankCity: "",
      memberEmail: "",
      memberContactIdorNumber: "",

      /* MERCHANT or ONLINE GATEWAY INFO */
      merchantBankCode: "",
      gatewayAccountId: "",
      gatewayAccountBankId: "",
      /* OTHER */
      transactionSuccessUrl: "",
      oriCurrency: "",
      oriAmount: "",
      language: uiHelper.getLanguage(),
      platform: uiHelper.getPlatform()
    },
    bankAccountDetail: {
      memberBankAccountId: "",
      memberBankAccountName: "",
      memberBankAccountNumber: "",
      memberBankName: "",
      memberBankProvince: "",
      memberBankBranch: "",
      memberBankCity: "",
      saveNewBank: false,
      memberCode: localStorage.getItem(SESSION.USERNAME),
      isUpdateBank: false
    },
    mobileNumberPrefixList: [
      {
        text: "+91",
        value: "INR"
      },
      {
        text: "+880",
        value: "BDT"
      }
    ],
    submittedTransactionResponse: null,
    msgDialog: {
      show: false,
      title: "",
      msg: ""
    },
    gatewayAccountBankName: "",
    alertShow: false,
    alertSuccess: false,
    alertMessage: "",
    multiplePhoneNo: false
  }),
  computed: {
    mobilePrefix() {
      let self = this;
      let prefix = _.find(this.mobileNumberPrefixList, function(p) {
        return p.value == self.transactionObj.currency;
      });
      if (prefix) return prefix.text;
      else return "";
    },
    memberContacts() {
      let contactList = this.$store.state.member.info.mobileList;
      // let _2ndMobile = this.$store.state.member.info.secondaryMobile;

      let _contacts = contactList.filter(x => x.is_verified).map((mobileNumber) => {
        return { text: this.mobilePrefix + " " + mobileNumber.value, value: mobileNumber.value };
      });

      return _contacts;
    },
    banks() {
      return this.$store.state.payment.banks;
    },
    availableMethods() {
      return this.$store.state.payment.availableWithdrawalMethods;
    },
    memberInfo() {
      return this.$store.state.member.info;
    },
    memberBankAccounts() {
      return this.$store.state.payment.memberBankAccounts;
    },
    transactionWithdrawalResponseComplete() {
      return this.$store.state.payment.transactionWithdrawalResponse.complete;
    },
    transactionWithdrawalResponse() {
      return this.$store.state.payment.transactionWithdrawalResponse;
    },
    memberWallet() {
      return this.$store.state.member.walletBalance;
    },
    withdrawStatus() {
      return this.$store.state.member.withdrawStatus;
    },
    onlinePaymentSupportedBanks() {
      return this.selectedMethod.banks;
    },
    restrictWithdrawal() {
      return this.$store.state.member.restrictWithdrawal.complete;
    }
  },
  watch: {
    memberWallet() {
      this.notLoading = true;
    },
    availableMethods() {
      let withdrawalActive = this.$store.state.payment.availableWithdrawalMethods.find(method => {
        return method.isActive && method.code != "NWLB";
      });
      this.isWithdrawalActive = withdrawalActive != undefined;
      if (!this.isWithdrawalActive) {
        this.showWithdrawalUnavailableDialog = true;
      }
      this.changeWithdrawalMethod(this.$store.state.payment.availableWithdrawalMethods[0]);
    },
    transactionWithdrawalResponse() {
      if (this.transactionWithdrawalResponse.complete) {
        if (!this.transactionWithdrawalResponse.success) {
          this.openAlert(this.transactionWithdrawalResponse.success, this.transactionWithdrawalResponse.error);
        }
      }
    },
    transactionWithdrawalResponseComplete() {
      let transactionResponse = this.$store.state.payment.transactionWithdrawalResponse;
      if (transactionResponse.complete) {
        try {
          this.$ga.event(
            "Payment",
            `Withdrawal`,
            `${this.transactionObj.methodCode.toUpperCase()} - ${transactionResponse.success ? "Success" : "Fail"}${!transactionResponse.success ? ` [Error : ${transactionResponse.code}]` : ""}`,
            transactionResponse.success ? this.transactionObj.amount : 0
          );
        } catch (err) {
          console.log(err);
        }

        this.transactionWithdrawalComplete(transactionResponse);
      }
    },
    restrictWithdrawal() {
      if (this.$store.state.member.restrictWithdrawal.complete) {
        if (this.$store.state.member.restrictWithdrawal.success) {
          if (this.$store.state.member.restrictWithdrawal.is_restrict) {
            this.popTurnoverMsg(this.$store.state.member.restrictWithdrawal);
          } else {
            this.openConfirmationDialog();
          }
        } else {
          this.openConfirmationDialog();
        }
        this.$store.dispatch(`${MEMBER_RESET_RESTRICT_WITHDRAWAL}`);
      }
    },
    withdrawStatus() {
      let status = this.$store.state.member.withdrawStatus;
      this.checkWithdrawStatus(status);
    }
  },
  created() {
    this.isPhoneVerified = this.IsMemberPhonesVerified();// this.memberInfo.mobile.is_verified;
    this.checkMemberTokenValidity();
    this.getPaymentMethod();
    this.getMemberWallet();
    this.checkRestrictWithdrawal();
    // this.$eventHub.$on('open-confirm-dialog', () => {
    //   this.openConfirmationDialog()
    // })
  },
  beforeMount() {
    this.$eventHub.$once("restrict-withdrawal-checking", () => {
      this.checkRestrictWithdrawal();
    });
  },
  methods: {
    routeToDeposit() {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT
      });
    },
    checkRestrictWithdrawal() {
      this.$store.dispatch(MEMBER_RESTRICT_WITHDRAWAL);
    },
    IsMemberPhonesVerified() {
      let _mobile = this.memberInfo.mobile;
      let _2ndMobile = this.memberInfo.secondaryMobile;

      if (_mobile.is_verified) {
        return true;
      }

      if (_2ndMobile.is_verified) {
        return true;
      }
      return false;
    },
    RedirectToProfilePage() {
      this.$router.push({ name: ROUTE_NAME.PERSONAL });
    },
    isMemberPhoneVerified() {
      let isVerified = this.memberInfo.mobile.is_verified;
      return isVerified;
    },
    maskBalance() {
      this.showBalance = !this.showBalance;
    },
    changeNumber(e) {
      if (this.transactionObj.memberBankAccountNumber.length <= 10) {
        return e.preventDefault();
      }
    },
    selectPaymentGateway(gateway) {
      this.transactionObj.gatewayAccountBankId = gateway.code;
      this.gatewayAccountBankName = gateway.name;
    },
    getAvailableMethodDisplayName(method) {
      let self = this;
      return method.localization.find(m => m.language == self.transactionObj.language).description;
    },
    preSelectMemberBankAccount() {
      if (this.memberBankAccounts.length > 0) {
        let memberBankAccounts = this.memberBankAccounts.find(x => x.id != "0");
        if (memberBankAccounts != null && memberBankAccounts != undefined) {
          this.transactionObj.memberBankAccountId = memberBankAccounts.id;
          this.transactionObj.memberBankName = memberBankAccounts.bankName;
          this.transactionObj.memberBankAccountNumber = memberBankAccounts.accountNumber;
          this.transactionObj.memberBankAccountName = memberBankAccounts.accountName;
        }
      }
    },
    transactionWithdrawalComplete(response) {
      this.getMemberWallet();
      this.processTransactionResponseDialog(response);
    },
    getMemberWallet() {
      this.notLoading = false;
      this.$store.dispatch(MEMBER_WALLET);
    },
    processTransactionResponseDialog(response) {
      if (response.code != 0 && !response.success) {
        let errorMsg = typeof response.error == "undefined" || response.error == null || response.error === "" ? errorCodeHelper.getErrorCodeDesc(response.code) : response.error;
        this.openAlert(false, errorMsg);
      } else {
        if (this.transactionObj.methodCode !== "lb") {
          this.openAlert(true, locale.getMessage(`message.withdrawalSubmitSuccess`));
        }
      }
      this.$store.dispatch(`${PAYMENT_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE}`);
    },
    updateMoneyAmount(data) {
      this.transactionObj.amount = data.replace(/^0+/, "");
    },
    changeWithdrawalMethod(method) {
      this.selectedMethod = method;
      this.transactionObj.methodCode = method.code;
      if (method.code.toLowerCase() == "lb") {
        this.localBankWithdrawalStep = 1;
      } else {
        this.transactionObj.memberBankAccountId = "";
        this.transactionObj.memberBankName = "";
        this.transactionObj.memberBankAccountNumber = this.memberInfo.mobile.value;
        this.transactionObj.memberBankAccountName = this.memberInfo.mobile.value;
        this.transactionObj.gatewayAccountBankId = method.code;
        this.gatewayAccountBankName = method.name;
        // this.transactionObj.gatewayAccountBankId = this.onlinePaymentSupportedBanks[0].code
        // this.gatewayAccountBankName = this.onlinePaymentSupportedBanks[0].name
      }
    },
    getMemberWithdrawStatus() {
      this.$store.dispatch(MEMBET_CHECK_WITHDRAW_STATUS);
    },
    proceedToMemberWithdrawalDetailTab() {
      if (this.$refs.withdrawalStepOneForm.validate()) {
        this.localBankWithdrawalStep = 2;
      }
    },
    backToPreviousStepDetailTab() {
      this.localBankWithdrawalStep = 1;
    },
    updateBankAccountData(bankAccountObj) {
      if (bankAccountObj != null) {
        this.tempMemberBankAccountId = "0";
        this.transactionObj.memberBankAccountId = "0";
        this.transactionObj.memberBankAccountName = bankAccountObj.memberBankAccountName;
        this.transactionObj.memberBankAccountNumber = bankAccountObj.memberBankAccountNumber;
        this.transactionObj.memberBankName = bankAccountObj.memberBankName;
        this.transactionObj.memberBankProvince = bankAccountObj.memberBankProvince;
        this.transactionObj.memberBankBranch = bankAccountObj.memberBankBranch;
        this.transactionObj.memberBankCity = bankAccountObj.memberBankCity;
        this.transactionObj.saveNewBank = bankAccountObj.saveNewBank;
      } else {
        this.transactionObj.memberBankAccountId = 0;
        this.transactionObj.memberBankAccountName = "";
        this.transactionObj.memberBankAccountNumber = "";
        this.transactionObj.memberBankName = "";
        this.transactionObj.memberBankProvince = "";
        this.transactionObj.memberBankBranch = "";
        this.transactionObj.memberBankCity = "";
        this.transactionObj.saveNewBank = "";
      }

      this.closeNewBankAccountDialog();
    },
    makeNewTransaction() {
      this.localBankWithdrawalStep = 1;
      this.transactionObj = {
        /* TRANSACTION INFO */
        transactionType: SHARED.WITHDRAWAL,
        methodCode: "",
        amount: 0,
        depositDateTime: "",
        attachmentFileType: "",
        attachment: "",
        ipAddress: "",
        saveNewBank: false,
        /* MEMBER INFO */
        memberCode: localStorage.getItem(SESSION.USERNAME),
        memberRealName: "",
        currency: localStorage.getItem(SESSION.CURRENCY),
        memberBankAccountId: "",
        memberBankAccountName: "",
        memberBankAccountNumber: "",
        memberBankName: "",
        memberBankProvince: "",
        memberBankBranch: "",
        memberBankCity: "",
        memberEmail: "",
        memberContactIdorNumber: "",

        /* MERCHANT or ONLINE GATEWAY INFO */
        merchantBankCode: "",
        gatewayAccountId: "",
        gatewayAccountBankId: "",
        /* OTHER */
        transactionSuccessUrl: uiHelper.getHostname(),
        oriCurrency: "",
        oriAmount: "",
        language: uiHelper.getLanguage(),
        platform: uiHelper.getPlatform()
      };
      this.submittedTransactionResponse = null;
    },
    checkMemberTokenValidity() {
      let requiredLoading = false;
      this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, { requiredLoading });
    },
    getPaymentMethod() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME),
        realName: this.memberInfo.name,
        currency: localStorage.getItem(SESSION.CURRENCY)
      };
      this.$store.dispatch(`${PAYMENT_AVAILABLE_METHOD}`, { obj });
    },

    updateSelectedMemberBankAccount(data) {
      if (data != 0) {
        this.tempMemberBankAccountId = data;
        let selectedMemberAccount = this.memberBankAccounts.find(x => x.id == data);
        this.transactionObj.memberBankAccountName = selectedMemberAccount.accountName;
        this.transactionObj.memberBankAccountNumber = selectedMemberAccount.accountNumber;
        this.transactionObj.memberBankName = selectedMemberAccount.bankName;
      } else if (data == 0) {
        this.openNewBankAccountDialog(false);
      }
    },
    submitTransaction() {
      if (this.$refs.transactionForm.validate()) {
        this.msgDialog.show = true;
      }
    },
    onCloseClick() {
      this.msgDialog.show = false;
    },
    submitPaymentTransaction() {
      let obj = this.transactionObj;
      obj.memberRealName = this.memberInfo.name;
      this.$store.dispatch(`${PAYMENT_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION}`, { obj });
      this.onCloseClick();
    },
    openNewBankAccountDialog(otherBank = true) {
      if (this.transactionObj.memberBankAccountId == 0 && otherBank) {
        this.bankAccountDetail = {
          memberBankAccountId: this.transactionObj.memberBankAccountId,
          memberBankAccountName: this.transactionObj.memberBankAccountName,
          memberBankAccountNumber: this.transactionObj.memberBankAccountNumber,
          memberBankName: this.transactionObj.memberBankName,
          memberBankProvince: this.transactionObj.memberBankProvince,
          memberBankBranch: this.transactionObj.memberBankBranch,
          memberBankCity: this.transactionObj.memberBankCity,
          saveNewBank: this.transactionObj.saveNewBank,
          memberCode: this.transactionObj.memberCode,
          isUpdateBank: false
        };
      } else {
        this.bankAccountDetail = null;
        this.bankAccountDetail = {
          memberBankAccountName: this.transactionObj.memberBankAccountName
        };
      }

      this.newBankAccountDialogShow = true;
    },
    closeNewBankAccountDialog() {
      this.transactionObj.memberBankAccountId = this.tempMemberBankAccountId;
      this.newBankAccountDialogShow = false;
    },
    memberBankAccountDisplayText(data) {
      if (data.value == 0) {
        return locale.getMessage(`label.${data.bankName}`);
      } else {
        return data.bankName;
      }
    },
    popTurnoverMsg(ticket) {
      let errorMessage = [locale.getMessage(`label.withdrawalRestrictedDesc`)];
      let dialog = this.initializePageDialogProperty();
      let turnOverNotMet = ticket.is_restrict;

      dialog.dialogXButton = null;
      dialog.title = locale.getMessage(`label.withdrawalRestricted`);

      if (!status.turnoverHit) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.turnoverNotMet`, [ticket.pending_ticket])}`);
        turnOverNotMet = true;
      }

      if (errorMessage.length > 1) {
        if (turnOverNotMet) {
          let route = ROUTE_NAME.TURNOVER_RECORD;
          let buttonTitle = locale.getMessage(`label.checkTurnoverStatus`);

          dialog.button.push({
            title: buttonTitle,
            action: () => {
              this.closeWithdrawalRestrictDialog();
              this.$router.push({
                name: route,
                params: {
                  tab: "RUNNING"
                }
              });
            }
          });
        }
        dialog.message = errorMessage;
        this.openPageDialog(dialog);
      }
    },
    checkWithdrawStatus(status) {
      let errorMessage = [locale.getMessage(`label.withdrawalRestrictedDesc`)];
      let dialog = this.initializePageDialogProperty();
      let profileNotComplete = false;
      let turnOverNotMet = false;

      dialog.dialogXButton = null;
      dialog.title = locale.getMessage(`label.withdrawalRestricted`);

      if (!status.emailVerified) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.emailNotVerify`)}`);
        profileNotComplete = true;
      }

      if (!status.mobileVerified) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.mobileNotVerify`)}`);
        profileNotComplete = true;
      }

      if (!status.lineVerified) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.lineNotVerify`)}`);
        profileNotComplete = true;
      }

      if (!status.profileComplete) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.profileIncomplete`)}`);
        profileNotComplete = true;
      }

      if (!status.turnoverHit && this.memberWallet.totalBalance > 0) {
        errorMessage.push(`${errorMessage.length}.) ${locale.getMessage(`label.turnoverNotMet`)}`);
        turnOverNotMet = true;
      }

      if (errorMessage.length > 1) {
        if (profileNotComplete) {
          dialog.button.push({
            title: locale.getMessage(`label.goToProfilePage`),
            action: () => {
              this.closePageDialog();
              this.$router.push({
                name: ROUTE_NAME.PROFILE
              });
            }
          });
        }

        if (turnOverNotMet) {
          let route = ROUTE_NAME.SLOT;
          let buttonTitle = locale.getMessage(`label.gotoSlotPage`);

          dialog.button.push({
            title: buttonTitle,
            action: () => {
              this.closePageDialog();
              this.$router.push({
                name: route
              });
            }
          });
        }
        dialog.message = errorMessage;
        this.openPageDialog(dialog);
      }
    },
    viewTransactionHistory() {
      this.$router.push({
        name: ROUTE_NAME.TRANSACTION,
        params: {
          tab: "withdrawal"
        }
      });
    },
    async restoreWithdrawal() {
      await this.$store.dispatch(`${WITHDRAWAL_RESTORE}`);
      this.$eventHub.$emit("close-page-dialog", true);
    },
    openConfirmationDialog() {
      if (this.availableMethods.length > 0) {
        let dialog = this.initializePageDialogProperty();
        dialog.title = locale.getMessage(`label.Confirmation`);
        dialog.message.push(locale.getMessage(`message.withdrawalWarning`));
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: () => this.restoreWithdrawal()
        });
        dialog.button.push({
          title: locale.getMessage(`button.cancel`),
          action: () => this.closePageDialog()
        });
        this.openPageDialog(dialog);
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: "",
        message: [],
        button: []
      };
      return initPageDialog;
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit("open-page-dialog", dialog);
    },
    closeWithdrawalRestrictDialog() {
      this.closePageDialog();
    },
    closePageDialog() {
      this.$store.dispatch(`${MEMBER_RESET_RESTRICT_WITHDRAWAL}`);
      this.$eventHub.$emit("close-page-dialog", true);
      this.goToDepositPage();
    },
    goToDepositPage() {
      this.$eventHub.$emit("change-funds-tab", "deposit");
    },
    openAlert(success, msg) {
      this.alertSuccess = success;
      this.alertMessage = msg;
      this.alertShow = true;
    }
  }
};
</script>
<style lang="scss">

.warning-text {
  color: var(--v-warning-base);
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.funds-wrapper {
  border-radius: 0px !important;

  .withdrawal-wallet {
    background-color: var(--v-primary-base);
    padding: 12px;

    .wallet-label {
      display: flex;
      gap: 4px;
    }

    .mask-balance {
      color: var(--v-secondary-lighten2);
      cursor: pointer;
    }

    .refresh-balance-icon {
      width: 24px;
      height: 24px;
      background-color: var(--v-secondary-lighten2);
      mask: url(/static/image/profile/icon-refresh-type01.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask: url(/static/image/profile/icon-refresh-type01.svg);
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      cursor: pointer;
    }

    .balance {
      font-size: 24px;
      text-align: right;
      display: flex;
      justify-content: end;
    }
  }
}

.payment-dialog-title {
  color: #acacac;
}

.card_container {
  margin-top: 70px;
  border-radius: 20px !important;

  &.nonBanking {
    margin-top: 0;
  }
}

.gateway-button {
  width: 60px;

  .v-btn__content {
    img {
      width: 44px;
      height: 44px;
    }
  }
}

.active-gateway-button {
  label {
    color: white;
  }
}

@media (max-width: 599px) {
  .card_container {
    margin-top: 20px;
    border-radius: 20px !important;
  }
  .bank-account-card {
    min-width: 250px;
  }
}
</style>
